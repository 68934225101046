@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  display: flex;
  height: 100vh;
  width: 90%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  @include width-viewport;
  row-gap: 20px;
  .content {
    background-color: $blue-3;
    padding: 35px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: white;
    .title {
      color: $blue-5;
      font-size: 37px;
      font-weight: 700;
      margin-bottom: 35px;
    }
    .body {
      margin-top: 25px;
      margin-bottom: 75px;
      font-size: 20px;
      line-height: 1.7rem;
      text-align: start;
    }
  }
  .gif{
    width: 30%;
    min-width: 350px;
  }
}

@import '../../styles/variables.scss';

.container {

    & :global(.MuiOutlinedInput-notchedOutline) {
        border: 2px solid;
        min-height: 16px
    }

    & :global(.MuiInputBase-input) {
        padding: 0;
        text-align: center;
    }

    & input::-webkit-outer-spin-button {
        display: none,
    }

    & input::-webkit-inner-spin-button {
        display: none,
    }
}
@import "../../styles/variables.scss";

.CalculationOnlineMenu{
  width: 100 vw;
  display: flex;

  .panel{
    margin: 0 auto;
  }
  
  .sideBar{
    background-color: gray;
  }
}

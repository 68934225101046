@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.emptyState {
  color: $red-1;
  width: 70%;
  min-height: 28px;
  font-size: 20px;
  border-radius: 16px;
  border: 2px solid $red-1;
  padding: 12px;
  justify-content: center;
  align-items: center;
  @include width-viewport;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 12px;
  @include width-viewport;
  gap: 2rem;

  .resultsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .section {
      display: flex;
      flex-direction: column;
      color: $gray-1;
      font-size: 1.25rem;
      text-align: justify;
      gap: 1rem;

      .label {
        text-align: start;
        font-weight: 500;

        @media screen and (max-width: $breakpoint-sm) {
          font-size: 1rem;
        }
      }

      .results {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-around;
        align-items: center;
      }

      .graphSection {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;

        .graph {
          scale: 0.5;
          max-height: 300px;

          @media screen and (max-width: $breakpoint-sm) {
            scale: 0.4;
          }
        }
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: left;

      .button {
        width: 150px;
        color: $green_4;
        border-radius: 0;
        font-size: 12px;
        font-weight: 500;
        background-color: $white-1;
      }

      .button:hover {
        background-color: $blue-1;
        color: $white-1;
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
          0 17px 50px 0 rgba(0, 0, 0, 0.19);
      }

      .selectedMenu {
        @extend.button;
        background-color: $green-5;
      }

      .costsButton {
        @extend.button;
      }
    }

    .divider {
      margin-bottom: 24px;
    }

    .tableContainer {
      width: 100%;
      display: flex;
    }

    .resultMessage {
      margin-top: 32px;
      color: $blue-3;
      display: flex;
      justify-content: center;
      margin-bottom: 12px;

      .message {
        border-radius: 10px;
        padding: 24px;
        background-color: $green-5;
        width: 90%;
        text-align: left;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0px;
      }

      .treeEquivalenceMessage {
        text-align: center;
        font-size: 16px;
        letter-spacing: 0px;
        line-height: 20px;

        .accents {
          color: $green-4;
        }
      }
    }

    .chartContainer {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      width: 100%;
      justify-content: center;
    }
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .container {
    padding: 20px;

    .resultsContainer {
      .buttonContainer {
        display: flex;
        justify-content: left;

        .button {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    padding: 20px;

    .resultsContainer {
      .buttonContainer {
        display: flex;
        justify-content: left;

        .button {
          width: 220px;
          font-size: 16px;
        }
      }

      .results {
        flex-direction: row;
        justify-content: space-around;
      }

      .resultMessage {
        .message {
          font-size: 18px;
          line-height: 20px;
        }

        .treeEquivalenceMessage {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .container {
    .resultsContainer {
      .buttonContainer {
        .button {
          width: 310px;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .container {
    .resultsContainer {
      .resultMessage {
        .message {
          font-size: 24px;
          line-height: 28px;
        }

        .treeEquivalenceMessage {
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }
}

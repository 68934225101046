@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  margin-top: 100px;
  justify-content: center;
  width: 90%;
  flex-direction: column;
  color: $gray-1;
  @include width-viewport;

  .content {
    width: 80%;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;

    .header {
      color: $blue-4;
      font-size: 30px;
      margin-bottom: 15px;
    }

    .body {
      font-weight: 400;
      text-align: left;
    }

    .instructions {
      margin-top: 15px;
      text-align: left;
    }

    @media screen and (max-width: $breakpoint-lg) {
      width: 90%;
    }
  }
}

@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.container {
  display: flex;
  width: 80%;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 150px;
  margin-bottom: 50px;
  @include width-viewport;
  row-gap: 30px;

  .gif {
    width: 250px;
  }

  .text {
    color: $gray-1;
    max-width: 50%;
    min-width: 330px;
    text-align: left;
    font-size: 24px;
    font-weight: 300;

    .header {
      font-weight: 700;
      font-size: 30px;

      .accents {
        color: $blue-4;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xxl) {
  .container {
    width: 90%;
    margin-top: 100px;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .container {
    .gif {
      width: 200px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .container {
    .text {
      text-align: center;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .container {
    .text {
      text-align: center;
    }

    .gif {
      width: 150px;
    }
  }
}

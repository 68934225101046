@import "../../styles/variables.scss";

.tableContainer {
  width: 100%;

  .energySources {
    color: $gray-1;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    border: 1px solid $gray-1;
    background: $blue-6;
    padding: 0px 4px;
    min-width: 200px;
  }

  .cell {
    color: $gray-1;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    border: 1px solid $gray-1;
    padding: 2px 0 2px 12px;
  }
}

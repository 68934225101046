@import "../../styles/variables.scss";

.container {
  flex-flow: column;
  align-items: center;
}

.label {
  position: fixed;
  bottom: 15%;
  right: 0;
  padding: 10px;
}

.flag {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.languageFlag{
  border: none;
  height: 25px;
}

.buttonLanguge{
  height: 30px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  background-color: transparent;
}

.buttonES{
  @extend .buttonLanguge;
  background-image: url('../../assets/PungoIcons/languageEs.svg');
  &:hover {
    cursor: pointer;
    background-image: url('../../assets/PungoIcons/languageEsHover.svg');
  }
  &.inactive{
    display:none;
  }
}

.buttonEN{
  @extend .buttonLanguge;
  background-image: url('../../assets/PungoIcons/languageEn.svg');
  &:hover {
    cursor: pointer;
    background-image: url('../../assets/PungoIcons/languageEnHover.svg');
  }
  &.inactive{
    display:none;
  }
}

@import "../../../../styles/variables.scss";

.grid {
  width: 90vw;
  height: 60vh;

  .upperActions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 1rem;
  }
}

.actions {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  color: $gray-9;

  .delete {
    &:hover {
      color: $red-1;
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .edit {
    &:hover {
      color: $blue-4;
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.headerCellContainer {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  .arrowContainer {
    margin-bottom: 1rem;

    .arrow {
      color: $gray-2;
      opacity: 0.15;

      &:hover {
        opacity: 1;
      }
    }

    .selectedColumnArrow {
      color: $gray-1;
      opacity: 1;
    }
  }

  .rangeDate {
    display: flex;
    gap: 2rem;
  }
}

.deleteModal {
  width: 30%;
  height: min-content;
  max-height: 70%;

  @media screen and (max-width: $breakpoint-md) {
    width: 50%;
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 70%;
  }
}

.table :global(.MuiDataGrid-selectedRowCount) {
  display: none !important;
  color: $gray-1;
  border-color: red;
}
.table :global(.MuiDataGrid-footerContainer) {
  justify-content: flex-end !important;
  color: $gray-1;
}
.table :global(.MuiDataGrid-row.Mui-selected) {
  background: transparent !important;
  color: $gray-1;
}

.table :global(.MuiDataGrid-row) {
  color: $gray-1;
  &:hover {
    background: transparent !important;
  }
}
.table :global(.MuiDataGrid-columnsContainer) {
  color: $gray-1;
}
.table :global(.MuiTablePagination-root) {
  color: $gray-1;
}

.table :global(.MuiDataGrid-iconSeparator) {
  visibility: hidden;
}

.table :global(.MuiDataGrid-cell) {
  &:focus-within,
  &:focus {
    outline: solid transparent 1px !important;
  }
}

.table :global(.MuiDataGrid-columnHeader) {
  &:focus-within,
  &:focus {
    outline: solid transparent 1px !important;
  }
}

.footerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0 0.5rem 0.5rem;

  .paginationContainer {
    display: flex;
    align-items: center;

    .footerIcon {
      color: $gray-1;
    }
    .currentPage {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
    }
  }

  .rowsPerPageContainer {
    min-width: 7rem;
  }
}

.addIcon {
  color: $gray-1;
}

@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  @include width-viewport;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    align-items: center;
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 25px;
    color: $gray-1;
    font-weight: 300;
    font-size: 1rem;

    .form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 350px;

      .title {
        color: $gray-1;
        font-size: 30px;
        font-weight: 700;
      }
    }
    .img {
      max-width: 420px;
    }
  }
}

@media screen and (max-width: $breakpoint-xxl) {
  .wrapper {
    .content {
      width: 100%;
      justify-content: space-around;
      .img {
        max-width: 350px;
      }
    }
  }
}

@import "styles/variables.scss";

.modal {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
  column-gap: 20px;
  width: 100%;
  row-gap: 20px;
  @media screen and (max-width: $breakpoint-sm) {
    grid-template-columns: minmax(200px, 1fr);
  }
  .centeredItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.modalParentForCreate {
  height: fit-content;
  width: 60%;
}

@import "../../../../styles/mixins.scss";
@import "../../../../styles/variables.scss";

.teamContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include width-viewport;

  .carouselContainer {
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }

  .cardContainer {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  @media screen and (max-width: $breakpoint-xxl) {
    display: grid;
    row-gap: 16px;
    column-gap: auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
  }
  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
  }
}

@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  .container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    row-gap: 60px;
    column-gap: 24px;
    @include width-viewport;

    .carouselContainer {
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
    }

    .cardContainer {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    @media screen and (max-width: $breakpoint-xxl) {
      justify-content: center;
      max-width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      justify-content: center;
      column-gap: 12px;
      row-gap: 32px;
    }
    @media screen and (max-width: $breakpoint-lg) {
      display: flex;
    }
  }
}

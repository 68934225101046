@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

:global(.MuiMenu-list) {
  & :global(.MuiMenuItem-root) {
    font-size: 14px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding: 12px;
  @include width-viewport;

  .accordionContainer {
    justify-content: center;
    min-width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;

    & :global(.MuiChip-root) {
      @media screen and (max-width: $breakpoint-sm) {
        max-width: 200px;
      }
    }
    .parameters {
      gap: 2rem;
      display: flex;
      flex-direction: column;
      .wellName {
        max-width: fit-content;
      }

      .note {
        width: 100%;
        color: $gray-1;
        text-align: left;
        font-size: 12px;
      }
    }

    .calculateButton {
      max-width: 4rem;
      margin-top: 3rem;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  :global(.MuiMenu-list) {
    & :global(.MuiMenuItem-root) {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: $breakpoint-xl) {
  :global(.MuiMenu-list) {
    & :global(.MuiMenuItem-root) {
      font-size: 18px;
    }
  }
}

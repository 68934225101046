@import "../../styles/variables.scss";

.mainContainer :global(.MuiPaper-root) {
  background: inherit;
  height: fit-content;
}

.mainContainer {
  color: $gray-1;

  .innerContainer {
    justify-content: center;
    
    .titleContainer {
      width: 100%;
      padding: 0.76rem 2rem;
      border: 1.5px solid $gray-1;
      border-radius: 64px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $gray-1;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 24px;
      position: relative;

      @media screen and (max-width: $breakpoint-md) {
        font-size: 1rem;
      }
      @media screen and (min-width: $breakpoint-xlg) {
        font-size: 1.5rem;
      }

      .accordionTitle {
        align-self: center;
        text-align: center;
        width: 100%;
      }

      .icon {
        margin-left: 2rem;
      }
    }

    .titleContainerNoBorder {
      width: 100%;
      padding: 0.76rem 2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.5rem;
      font-weight: 800;
      line-height: 24px;
      position: relative;
      color: white;
      background-color: $blue-3;
      
      .accordionTitle {
        margin: 0 auto;
        padding-left: 75px;
        width: 100%;
        font-size: 1.3rem;
      }

      .icon {
        margin-left: 2rem;
      }
    }
  }

  .content {
    color: $gray-1;
  }
}

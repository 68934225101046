@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins.scss";

.container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  @include width-viewport;
  gap: 20px;

  .accordionContainer {
    width: 100%;

    .chemicalInyectionContainer {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .note {
        width: 100%;
        color: $gray-1;
        text-align: left;
        font-size: 12px;
      }
    }

    .image {
      height: 400px;

      @media screen and (max-width: $breakpoint-xxl) {
        height: 300px;
      }
      @media screen and (max-width: $breakpoint-lg) {
        height: 250px;
      }
      @media screen and (max-width: $breakpoint-md) {
        height: 200px;
      }
      @media screen and (max-width: $breakpoint-sm) {
        height: 120px;
      }
    }
  }
}

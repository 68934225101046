@import "../../../styles/variables.scss";

.containerFAQSection {

  @media screen and (min-width: $breakpoint-sm) {
    width: 95%;
  }

  @media screen and (min-width: $breakpoint-sm) {
    width: 85%;
  }

  @media screen and (min-width: $breakpoint-lg) {
    width: 90%;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    width: 55%;
  }
  
  align-self: center;
  margin: auto;
}

.text {
  align-self: center;
  text-align: left;
  width: 90%;
  margin: auto;
  font-size: 12px;
  color: white;
  scroll-padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;

  li{
    line-height: 30px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .text {
    font-size: 18px;
    line-height: 26px;
  }
}

@media screen and (min-width: $breakpoint-xxxl) {
  .text {
    font-size: 21px;
  }
}

@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

@mixin popularRowCss($color) {
  @include resTablePopularFontSize();

  background-color: $color;
  color: white;
  border: 0.25vw solid $color;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 3px 0 6px 0 !important;
}

@mixin titleRowCss($color) {
  @include resTableTitleFontSize();

  color: $color;
  font-weight: 600;
  border-left: 0.25vw solid $color;
  border-right: 0.25vw solid $color;
  border-top: 0.25vw solid $color;
  padding: 2vw 0;
}

@mixin priceRowCss($color) {
  color: $color;
  border-left: 0.25vw solid $color;
  border-right: 0.25vw solid $color;
  padding-bottom: 1vw;
}

@mixin lastRowCss($color) {
  color: $color;
  border-left: 0.25vw solid $color;
  border-right: 0.25vw solid $color;
  border-bottom: 0.25vw solid $color;

  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 1vw 0 2vw 0;
}

@mixin columnCss($color) {
  @include resTableIconSize();

  width: 17%;
  color: $color;
  border-left: 0.25vw solid $color;
  border-right: 0.25vw solid $color;
}

@mixin resResume($color) {
  @include resTableResumeFontSize();

  color: $color;
  border-left: 0.25vw solid $color;
  border-right: 0.25vw solid $color;
  padding: 0 1vw 0.5vw 1vw;
  vertical-align: top;
}

@mixin descriptionCss() {
  width: 30%;
  color: white;
  text-align: left;
}

@mixin resTableDesripcionFontSize() {
  font-size: $font-size-table-cell;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: $font-size-table-cell-sm;
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: $font-size-table-cell-md;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: $font-size-table-cell-lg;
  }

  @media screen and (min-width: $breakpoint-xl) {
    font-size: $font-size-table-cell-xl;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    font-size: $font-size-table-cell-xxl;
  }

  @media screen and (min-width: $breakpoint-xxxl) {
    font-size: $font-size-table-cell-xxxl;
  }
}

@mixin resTableTitleFontSize() {
  font-size: $font-size-table-title;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: $font-size-table-title-sm*0.7;
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: $font-size-table-title-md*0.7;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: $font-size-table-title-lg*0.7;
  }

  @media screen and (min-width: $breakpoint-xl) {
    font-size: $font-size-table-title-xl*0.7;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    font-size: $font-size-table-title-xxl*0.7;
  }

  @media screen and (min-width: $breakpoint-xxxl) {
    font-size: $font-size-table-title-xxxl*0.7;
  }
}

@mixin resTablePopularFontSize() {
  font-size: $font-size-table-cell * 1.38;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: $font-size-table-cell-sm * 1.38;
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: $font-size-table-cell-md * 1.38;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: $font-size-table-cell-lg * 1.38;
  }

  @media screen and (min-width: $breakpoint-xl) {
    font-size: $font-size-table-cell-xl * 1.38;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    font-size: $font-size-table-cell-xxl * 1.38;
  }

  @media screen and (min-width: $breakpoint-xxxl) {
    font-size: $font-size-table-cell-xxxl * 1.38;
  }
}

@mixin resTableResumeFontSize() {
  font-size: $font-size-table-resume;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: $font-size-table-resume-sm;
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: $font-size-table-resume-md;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: $font-size-table-resume-lg;
  }

  @media screen and (min-width: $breakpoint-xl) {
    font-size: $font-size-table-resume-xl;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    font-size: $font-size-table-resume-xxl;
  }

  @media screen and (min-width: $breakpoint-xxxl) {
    font-size: $font-size-table-resume-xxxl;
  }
}

@mixin resTablePriceFontSize() {
  font-size: $font-size-table-price;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: $font-size-table-price-sm;
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: $font-size-table-price-md;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: $font-size-table-price-lg;
  }

  @media screen and (min-width: $breakpoint-xl) {
    font-size: $font-size-table-price-xl;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    font-size: $font-size-table-price-xxl;
  }

  @media screen and (min-width: $breakpoint-xxxl) {
    font-size: $font-size-table-price-xxxl;
  }
}

@mixin resTableIconSize() {
  font-size: $font-size-table-icon*0.3;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: $font-size-table-icon-sm*0.3;
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: $font-size-table-icon-md*0.3;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: $font-size-table-icon-lg*0.3;
  }

  @media screen and (min-width: $breakpoint-xl) {
    font-size: $font-size-table-icon-xl*0.3;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    font-size: $font-size-table-icon-xxl*0.3;
  }

  @media screen and (min-width: $breakpoint-xxxl) {
    font-size: $font-size-table-icon-xxxl*0.3;
  }
}

@mixin buttonTemplate {
  border: none;
  padding: 1vw;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 2vw 1vw 3vw 1vw;
  border-radius: 50%;
  cursor: pointer;
}

.containerPlansTable {
  @include width-viewport;

  table {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: separate;
    border-spacing: 0;
    border: 0;

    @media screen and (min-width: $breakpoint-sm) {
      width: 95%;
    }

    @media screen and (min-width: $breakpoint-sm) {
      width: 85%;
    }

    @media screen and (max-width: $breakpoint-md) {
      width: 70%;
    }
  }

  td {
    @include resTableDesripcionFontSize();
  }

  .rowPopular01 {
    @include popularRowCss($green-1);
  }

  .rowPopular02 {
    @include popularRowCss($green-3);
  }

  .rowPopular03 {
    @include popularRowCss($blue-2);
  }

  .rowTitle01 {
    margin-top: var(--marginTop);
    margin-bottom: var(--marginBottom);

    @include titleRowCss($green-1);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .rowTitle02 {
    @include titleRowCss($green-3);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .rowTitle03 {
    @include titleRowCss($blue-2);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .rowPrice01 {
    @include priceRowCss($green-1);
    text-align: left;
  }

  .rowPrice02 {
    @include priceRowCss($green-3);
    text-align: left;
  }

  .rowPrice03 {
    @include priceRowCss($blue-2);
    text-align: center;
  }

  .description {
    @include descriptionCss();
    padding-left: 3.4vw;
  }

  .descriptionTitle {
    @include descriptionCss();
    padding: 1vw 0 1vw 2.1vw;
    font-weight: 800;
  }

  .descriptionBottom {
    @include descriptionCss();
    border-bottom: 0 solid $green-2;
  }

  .resume01 {
    @include resResume($green-1);
  }

  .resume02 {
    @include resResume($green-3);
  }

  .resume03 {
    @include resResume($blue-2);
  }

  .col01 {
    @include columnCss($green-1);
  }

  .col02 {
    @include columnCss($green-3);
  }

  .col03 {
    @include columnCss($blue-2);
  }

  .whiteSpace {
    width: 2%;
  }

  .lastRow03 {
    @include lastRowCss($green-1);
  }

  .lastRow05 {
    @include lastRowCss($green-3);
  }

  .lastRow07 {
    @include lastRowCss($blue-2);
  }

  .specialTitle {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .group {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .symbol {
        font-size: 28px;
      }

      .cash {
        font-size: 34px;
        vertical-align: bottom;
      }

      .number {
        @include resTablePriceFontSize();
        font-weight: 400;
      }
    }

    .month {
      @include resTableDesripcionFontSize();
    }
  }

  .button1 {
    @include buttonTemplate();
    background-color: $green-1;
  }

  .button2 {
    @include buttonTemplate();
    background-color: $green-3;
  }

  .button3 {
    @include buttonTemplate();
    background-color: $blue-2;
  }

  .buttonSuscribe{
    font-size: 1rem;
    // width: 130px;
    // margin: 11px 0;
  }

  #planButtonSelector {
    margin-top: 5vw;

    @media screen and (min-width: $breakpoint-md) {
      display: none;
    }
  }

  .separatorTitle {
    background: rgba(142, 193, 75, 0.2);
    height: 7px;
  }

  .separator {
    background: rgba(142, 193, 75, 0.2);
    height: 3px;
  }
}

.table1 {
  td:nth-child(3) {
    display: "";
  }

  td:nth-child(4) {
    display: "";
  }

  td:nth-child(5) {
    display: "";
  }

  td:nth-child(6) {
    display: "";
  }

  td:nth-child(7) {
    display: "";
  }

  @media screen and (max-width: $breakpoint-md) {
    td:nth-child(4) {
      display: none;
    }

    td:nth-child(5) {
      display: none;
    }

    td:nth-child(6) {
      display: none;
    }

    td:nth-child(7) {
      display: none;
    }
  }
}

.table2 {
  td:nth-child(3) {
    display: "";
  }

  td:nth-child(4) {
    display: "";
  }

  td:nth-child(5) {
    display: "";
  }

  td:nth-child(6) {
    display: "";
  }

  td:nth-child(7) {
    display: "";
  }

  @media screen and (max-width: $breakpoint-md) {
    td:nth-child(3) {
      display: none;
    }

    td:nth-child(4) {
      display: none;
    }

    td:nth-child(6) {
      display: none;
    }

    td:nth-child(7) {
      display: none;
    }
  }
}

.table2 {
  td:nth-child(3) {
    display: "";
  }

  td:nth-child(4) {
    display: "";
  }

  td:nth-child(5) {
    display: "";
  }

  td:nth-child(6) {
    display: "";
  }

  td:nth-child(7) {
    display: "";
  }

  @media screen and (max-width: $breakpoint-md) {
    td:nth-child(3) {
      display: none;
    }

    td:nth-child(4) {
      display: none;
    }

    td:nth-child(6) {
      display: none;
    }

    td:nth-child(7) {
      display: none;
    }
  }
}

.table3 {
  td:nth-child(3) {
    display: "";
  }

  td:nth-child(4) {
    display: "";
  }

  td:nth-child(5) {
    display: "";
  }

  td:nth-child(6) {
    display: "";
  }

  td:nth-child(7) {
    display: "";
  }

  @media screen and (max-width: $breakpoint-md) {
    td:nth-child(3) {
      display: none;
    }

    td:nth-child(4) {
      display: none;
    }

    td:nth-child(5) {
      display: none;
    }

    td:nth-child(6) {
      display: none;
    }
  }
}

.chevronLeft {
  position: fixed;
  top: 2300px;
  left: 10px;
  width: 35px;
  height: 20px;

  img {
    width: 100%;
    cursor: pointer;
  }

  @media screen and (min-width: $breakpoint-md1) {
    display: none;
  }
}

.chevronRight {
  position: fixed;
  top: 2300px;
  right: 10px;
  width: 35px;
  height: 20px;

  img {
    width: 100%;
    cursor: pointer;
  }

  @media screen and (min-width: $breakpoint-md1) {
    display: none;
  }
}

.check {
  vertical-align: middle;
}
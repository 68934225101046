@import "../../styles/variables.scss";

.container {
  align-items: center;
  margin-bottom: 60px;
  color: $gray-1;

  &.flexDistribution {
    display: flex;
    flex-direction: column;
  }

  .header {
    font-size: 40px;
    // margin-bottom: 30px;
    font-weight: 700;
    width: 90%;
    margin: 0 auto 30px auto;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 26px;
    }
  }

  .description {
    width: 70%;
    font-size: 26px;
    font-weight: 400;
    &.flexDistribution {
      margin-bottom: 30px;
    }

    &.blockDistribution {
      margin: 0 auto 30px auto;
    }

    @media screen and (max-width: $breakpoint-lg) {
      width: 90%;
    }
  }

  .children {
    width: 100%;
    
    &.blockDistribution {
      margin: 0 auto;
    }

    &.flexDistribution {
      display: flex;
      justify-content: center;
    }

    @media screen and (min-width: $breakpoint-xlg) {
      width: 90%;
    }
  }
}

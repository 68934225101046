@import "../../../../../../styles/variables.scss";

.container {
  width: 100%;
  height: 100%;

  .table {
    margin-bottom: 3rem;
  }

  .tabs {
    overflow-x: auto;
    white-space: nowrap;
  }

  .image {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    scale: 0.7;

    .graph {
      max-height: 150px;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    color: $gray-1;
    font-size: 1.25rem;
    text-align: justify;
    gap: 1rem;

    .label {
      text-align: start;
      font-weight: 500;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 1rem;
      }
    }

    .results {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }
  }
  .divider {
    border-bottom: 1px solid $gray-9;
    margin: 2rem;
  }
}

@import "../../../styles/mixins.scss";
@import "../../../styles/variables.scss";

.container {
  width: 100%;
  align-items: center;
  margin-top: 40px;
  @include width-viewport;
}

.bookingButton {
  width: 500px;
  font-weight: bold !important;
  font-size: 20px !important;
}

@media screen and (max-width: $breakpoint-sm) {
  .bookingButton {
    width: fit-content;
    font-size: 16px !important;
  }
}

.contactLinksContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
 

  .linkedInButton {
    font-weight: 700;
    font-size: 30px;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    margin-right: 2vw;
    border: $blue-2 solid 3px;
    border-radius: 28px;
    color: $blue-2;

    &:hover {
      color: $gray-1;
      background: $blue-2;
    }
  }

  .whatsappButton {
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    margin-left: 2vw;
    background-image: url("../../../assets/PungoIcons/Whatsapp.svg");
    background-repeat: no-repeat;
    background-size: contain;
    &:hover {
      color: $gray-1;
      background-image: url("../../../assets/PungoIcons/WhatsappHover.svg");
      cursor: pointer;
    }
  }
}
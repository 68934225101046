@import "styles/variables.scss";

.modalParentForEdit {
  width: 80%;
  height: 80%;

  .modal {
    width: 100%;
    height: 100%;
    margin: auto;
    .inputs {
      display: grid;
      grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
      column-gap: 20px;
      row-gap: 20px;

      @media screen and (max-width: $breakpoint-sm) {
        grid-template-columns: minmax(200px, 1fr);
      }
    }
    .choicesTable {
      width: 100%;
      height: 300px;
      margin-top: 24px;
    }
  }
}

.modalParentForCreate {
  height: fit-content;
  width: 80%;

  .modal {
    width: 100%;
    margin: auto;
    .inputs {
      display: grid;
      width: 100%;
      height: 100%;
      grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
      column-gap: 20px;
      row-gap: 20px;

      @media screen and (max-width: $breakpoint-sm) {
        grid-template-columns: minmax(200px, 1fr);
      }
    }
  }
}

.modalParentForChoice {
  height: 40%;
  width: 70%;
  min-height: 250px;

  .modal {
    width: 100%;
    margin: auto;
    .inputs {
      display: grid;
      width: 100%;
      height: 100%;
      grid-template-columns: minmax(300px, 1fr) minmax(300px, 1fr);
      column-gap: 20px;
      row-gap: 20px;

      @media screen and (max-width: $breakpoint-sm) {
        grid-template-columns: minmax(300px, 1fr);
      }
    }
  }
}

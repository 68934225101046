@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  border-radius: 30px;
  padding: 15px;
  font-size: 16px;
  @include content-shadow;
  @include remove-shadow-viewport;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 15px 0;
  }

  .header {
    margin-bottom: 8px;
    font-weight: 700;
  }

  .image {
    margin-bottom: 25px;
    max-height: 150px;
    width: 90%;
  }

  .description {
    color: $gray-1;
    font-weight: 300;
  }
}

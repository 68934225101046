@import "styles/variables.scss";

.modalParentForEnableModel {
  width: 60%;
  height: fit-content;

  .modalContent {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
    gap: 20px;

    .switch {
      width: 100%;
    }
  }
}

.modalChildrenForEnable {
  overflow-y: auto!important;
}

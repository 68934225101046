@import "../../styles/variables.scss";

.container {
  min-width: fit-content;
  max-width: fit-content;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: $gray-1;
  gap: 0.75rem;

  .description {
    font-weight: 500;
    text-align: center;
  }

  .result {
    border-radius: 16px;
    padding: 8px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    width: 100%;
  }

  .reduced {
    font-weight: 400;
    font-size: 16px;
  }
}

.image {
  margin-top: 10px;
  height: 100px;
  width: 100px;
}

@media screen and (min-width: $breakpoint-sm) {
  .container {
    font-size: 20px;
    line-height: 26px;

    .reduced {
      font-size: 20px;
    }

    .image {
      margin-top: 10px;
      height: 160px;
      width: 160px;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .container {
    .image {
      height: 200px;
      width: 200px;
    }
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .container {
    .reduced {
      font-size: 24px;
    }

    .image {
      margin-top: 10px;
      height: 280px;
      width: 280px;
    }
  }
}

@import "../../styles/variables.scss";

.modal {
  background: $gray-1;
  height: min-content;
  max-height: 70%;
  border-radius: 10px;
  margin: 0;
  color: $gray-1;
  padding: 30px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $gray-3;

  .header {
    text-align: center;
    margin-bottom: 25px;
    color: $blue-4;
    font-size: 20px;
    font-weight: 700;
  }

  .children {
    height: 100%;
    overflow-y: scroll;
    padding-top: 5px;
    display: flex;
    width: 100%;
  }

  .actionSection {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    .primaryAction {
      margin-left: 30px;
    }
  }
}
.container:global(.MuiBackdrop-root.MuiModal-backdrop) {
  backdrop-filter: blur(5px);
}

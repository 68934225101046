@import "../../styles/variables.scss";

.planSelection {
  color: white;

  .title {
    font-size: 40px;
    font-weight: 700;
    width: 90%;
    margin: 0 auto 11px auto;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 26px;
    }
  }

  .blocks {
    margin: 0 auto;
    display: flex;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }
  }
}

.planDescription {
  width: 40%;
  font-size: 21px;
  padding: 15px 30px 15px 15px;
  text-align: left;
  box-shadow: $gray-1 1px 1px 4px;
  border-radius: 30px;
  margin: 0 auto;
  .txt {
    margin: 15px 0;
    .boldTxt {
      font-size: 23px;
      font-weight: 800;
      @media screen and (max-width: $breakpoint-lg) {
        font-size: 17px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    width: 80%;
    margin: 0 auto;
    font-size: 15px;
  }
}

.planOptions {
  width: 40%;
  margin: 0 auto;
  @media screen and (max-width: $breakpoint-lg) {
    width: 80%;
    margin: 0 auto;
  }
}

.planList {
  margin-top: 30px;
  text-align: left;
  @media screen and (max-width: $breakpoint-lg) {
    text-align: center;
  }
}

.planItem {
  margin: 25px 0;
}

.buttonPlanOptions {
  border-radius: 9px !important;
  width: 250px !important;
  font-size: 17px !important;
  font-weight: 400 !important;
}

.buttonPlanLink {
  margin: 0 0 0 35px !important;
  border-radius: 9px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

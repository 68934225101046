@import "../../styles/variables.scss";

.loader {
  position: fixed;
  z-index: 10;
  bottom: 32px;
  right: 32px;
}

:global(.MuiCircularProgress-colorPrimary) {
    color: $green-2;
  
  }
  
@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.container {
  width: 70%;
  margin: 0 auto;
}

.planSwitcher {
  margin: 47px auto 15px auto;
  gap: 30px; /* Adjust gap as needed */
}

.planButton {
  
  padding: 10px 20px;
  margin: 0 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: $green-4;
  font-weight: 800;
  cursor: pointer;
  border-radius: 12px;
  &.selected {
    padding: 20px 30px;
    background: $blue-1;
    box-shadow: 6px 6px 11px 3px rgba(0, 0, 0, 0.25);
    color: $white-1;
    border: 2px solid $blue-1;
    font-weight: bold;
    &:hover {
      color: $white-1;
    }
  }

  font-size: $font-size-table-cell;

  @media screen and (min-width: $breakpoint-sm) {
    font-size: $font-size-table-cell-sm;
  }

  @media screen and (min-width: $breakpoint-md) {
    font-size: $font-size-table-cell-md * 0.75;
  }

  @media screen and (min-width: $breakpoint-lg) {
    font-size: $font-size-table-cell-lg * 0.75;
  }

  @media screen and (min-width: $breakpoint-xl) {
    font-size: $font-size-table-cell-xl * 0.75;
  }

  @media screen and (min-width: $breakpoint-xxl) {
    font-size: $font-size-table-cell-xxl * 0.75;
  }

  @media screen and (min-width: $breakpoint-xxxl) {
    font-size: $font-size-table-cell-xxxl * 0.75;
  }
}
@import "../../../../../../styles/variables.scss";

.container {
  width: 100%;
  height: 100%;

  .table {
    margin-bottom: 3rem;
  }

  .tabs {
    overflow-x: auto;
    white-space: nowrap;
  }

  .section {
    display: flex;
    flex-direction: column;
    color: $gray-1;
    font-size: 1.25rem;
    text-align: justify;
    gap: 1rem;

    .label {
      text-align: start;
      font-weight: 500;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 1rem;
      }
    }

    .sortSelector {
      width: 100%;
      display: flex;
      gap: 20px;
      justify-content: flex-end;
    }

    .message {
      text-align: justify;
    }

    .barChartContainer {
      height: 400px;
      width: 100%;
      display: flex;

      .barChart {
        width: 100%;
      }
    }

    .results {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
    }
  }
  .divider {
    border-bottom: 1px solid $gray-9;
    margin: 2rem;
  }
}

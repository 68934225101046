@import "../../styles/variables.scss";

.container :global(.MuiPaper-root) {
  height: fit-content;
  background: $blue-6;
}

.container {
  border: 2px solid $gray-1;
  border-radius: 10px;
  width: 100%;

  .innerContainer {
    .titleContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      color: $gray-1;

      .icon {
        display: flex;
        align-items: center;
      }

      .accordionTitle {
        font-size: 14px;
        font-weight: 500;

        @media screen and (min-width: $breakpoint-sm) {
          font-size: 20px;
        }

        @media screen and (min-width: $breakpoint-md) {
          margin-right: 24px;
          font-size: 26px;
        }

        @media screen and (min-width: $breakpoint-xxl) {
          margin-right: 12px;
          font-size: 24px;
        }
      }
      .badge {
        @media screen and (min-width: $breakpoint-md) {
          margin-right: 40px;
        }
      }
    }
  }
}

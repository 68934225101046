
  .chartContainer{
    margin: auto 0 auto;
  }
  
  .canvas{
    width: 100%;
    margin: 0 auto;
  }

  #chartCanvas{
    width: 100%;
    object-fit: contain;
  }
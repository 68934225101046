.verifyingAccount {
  justify-content: center;
}

.recoveryPasswordModal {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  .recoveryText {
    text-align: left;
  }

  .recoveryInput {
    width: 100%;
  }
}

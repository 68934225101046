@import "../../styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  padding: 40px 0 60px;

  .content {
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: relative;

    .borderContainer {
      position: absolute;
    }

    .image {
      height: 72px;
      margin-top: -30px;
      margin-left: 4px;
    }

    .textContainer {
      padding-left: 10px;
      padding-right: 10px;
      font-size: 24px;
      line-height: 28px;
      max-width: 190px;
      font-size: 15px;
      line-height: 19px;
      text-align: center;

      .title {
        margin-top: 8px;
        font-weight: 700;
      }

      .description {
        color: $gray-1;
        font-weight: 400;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xlg) {
  .container {
    display: flex;
    align-items: center;

    .content {
      flex-direction: row;
      width: 897px;

      &.rowReverse {
        flex-direction: row-reverse;
      }

      .image {
        height: 100%;
        margin: 20px;
      }

      .textContainer {
        min-width: 665px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        font-size: 24px;
        line-height: 28px;
        padding: 0 48px 0 0;

        &.rowReverse {
          padding: 0 0px 0 60px;
          font-size: 24px;
          line-height: 28px;
          text-align: left;
          padding: 0;
        }

        .title {
          margin-bottom: 0px;
          font-weight: 700;
        }

        .description {
          color: $gray-1;
          font-weight: 400;
        }
      }
    }
  }
}

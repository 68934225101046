@import "../../styles/variables.scss";

.CalculationBatchPage {
  margin-top: 120px;
  
  .title {
    font-size: 2.4rem;
    font-weight: 800;
    margin-bottom: 15px;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: $breakpoint-xlg) {
      font-size: 2.5rem;
    }
  }

  .textContainer {
    width: 90%;
    margin: 0 auto;
    text-align: justify;
    text-justify: inter-word;
    hyphens: auto;
    border-bottom: 1px solid white;
  }

  .textContainer > * {
    margin-bottom: 15px;
  }
  
  .textContainer > *:last-child{
    margin-bottom: 15px;
  }

  li{
    margin-bottom: 10px;
  }


}

@import "styles/mixins.scss";
@import "styles/variables.scss"; 

.container{
  width: 90%;
  margin: 0 auto;
  border: 3px solid $gray-2;
  padding: 15px 10px 30px 10px;
}

.wrapper {
  margin: 0 auto;
}

.wrapper > * {
  height: 85px;
}

.submitSection{
  margin-top: 50px;
}

.submitButton{
  display:inline;
  position:relative;
}

.submitButtonOver {
  width: 500px;
  font-weight: bold !important;
  font-size: 20px !important;
  &:hover {
    background-color: $blue-2 !important;
  }
}

.submitNote{
  background-color: $gray-2;
  color: $errorColor;
  font-size: 17px;
  left: 60%;
  padding: 10px;
  position:absolute;
  top: -120px;
  font-weight: 600;
  width: 150px;
  z-index: 100;
  
}

@media screen and (max-width: $breakpoint-sm) {
  .submitButtonOver {
    width: 300px;
    font-size: 16px !important;
  }

  .submitNote{
    top: -65%;
    font-size:11px;
    width:100px;
  }
}

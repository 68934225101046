@import "../../styles/variables.scss";

.menu:global(.MuiPaper-root) {
  background: $gray-3;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  color: $gray-1;
  z-index: 10;
}

.icon {
  color: white;
}

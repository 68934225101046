@import 'styles/variables.scss';

:global(.MuiSnackbar-root) {
  border-radius: 7px;
  margin-right: 30px;

  &.success {
    border: 3px solid $green-1;
  }

  &.error {
    border: 3px solid $red-1;
  }
}

@import "../../styles/variables.scss";

.container {
  input:disabled {
    background: rgb(111, 111, 111);
    -webkit-text-fill-color: $gray-1;
  }

  :global(.MuiFormControl-root) {
    width: 100%;
  }

  // Input text color
  :global(.MuiFormLabel-root) {
    color: $gray-1;

    &:global(.Mui-disabled) {
      color: $gray-1;
      -webkit-text-fill-color: $gray-1;
    }
  }

  :global(.MuiInputBase-root) {
    color: $blue-4;
  }

  :global(.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline) {
    border-color: $gray-1;
  }

  :global(.MuiFormHelperText-root ) {
    color: $gray-1;
  }

  :global(.MuiSvgIcon-root ) {
    color: $gray-1;
  }

  .icon {
    color: $gray-1;
  }
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: $gray-2;
  caret-color: $gray-1;
}

.cancelButton {
  color: $gray-9;
}

:global(.MuiOutlinedInput-root) {
  padding-right: 0 !important;
}

@import "styles/variables.scss";

.modalParentForEdit {
  width: 50%;
  height: 40%;

  .modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: auto;
    column-gap: 20px;
    row-gap: 20px;

    .choicesTable {
      width: 100%;
      height: 300px;
      margin-top: 24px;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 70%;
    height: 40%;
  }
}

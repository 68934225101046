@import "../../styles/variables.scss";

.CalculationDemoBatchPage {
  margin-top: 120px;
  color: white;

  .title {
    font-size: 2.4rem;
    font-weight: 800;
    margin-bottom: 15px;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: $breakpoint-xlg) {
      font-size: 2.5rem;
    }
  }

  .title2 {
    font-size: 1.3rem;
    font-weight: 800;
    margin: 15px 15px 0 15px;
    background-color: $blue-3;
    padding: 1.3rem 0;
  }

  .textContainer {
    width: 90%;
    margin: 0 auto;
    text-align: justify;
    text-justify: inter-word;
    hyphens: auto;
    border-bottom: 1px solid white;
  }

  .textContainer > * {
    margin-bottom: 15px;
  }

  .textContainer > *:last-child {
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 10px;
  }

  .loadData {
    // width: 98%;
    margin: 0 15px;
  }

  .fileManager {
    background-color: $blue-3;
    margin: 0;
    .elementFlex {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 0px auto;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      .element {
        margin: 5px auto;
      }
    }
    
    .elementBlock{
      padding: 15px 0;
      display: flex;
      flex-wrap: wrap;
      gap:10px;
      justify-content: center;
      .elementRow{
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        gap: 15px;
      }
    }
  }

  .results {
    margin-top: 35px;
  }

  .selectBox {
    background-color: $blue-6;
    width: 320px;
    display: flex;
    border-radius: 30px;
    padding: 5px 21px;
    gap: 15px;
    .selectTitle {
      margin: auto 0;
    }
  }

  .selectBox:hover{
    background-color: $blue-1 !important;
    color: $blue-6 !important;
    box-shadow: 5px 5px 10px rgba(134, 176, 204, 0.5);
  }

  .selectButton {
    color: white !important;
    background-color: $blue-6 !important;
    width: 360px;
    font-size: 13px;
    padding: 11px 0;
  }

  .selectButton:hover {
    background-color: $blue-4 !important;
    color: $blue-6 !important;
    box-shadow: 5px 5px 10px rgba(134, 176, 204, 0.5);
  }

  .fileInput{
    margin: 0 auto;
    padding: 15px 0 15px 15px;
    border-radius: 30px;
    background-color: $blue-6;
    width: 350px;
  }
  
  .fileInput:hover{
    background-color: $blue-4 !important;
    color: $blue-6 !important;
    box-shadow: 5px 5px 10px rgba(134, 176, 204, 0.5);
  }

  .uploadButton {
    color: white !important;
    background-color: $blue-6 !important;
    width: 360px;
    font-size: 15px;
    border-radius: 30px;
    padding: 11px 0;
    border: none;
  }

  .uploadButton:hover {
    background-color: $blue-4 !important;
    color: $blue-6 !important;
    box-shadow: 5px 5px 10px rgba(134, 176, 204, 0.5);
  }

  .buttonMessage{
    display: flex;
    justify-content: center;
    gap: 5px;
    .buttonIcon{
      display: inline;
    }
    .buttonText{
      margin-top: 3px;
      display: inline;
    }
  }
}

.errors{
  font-size: 31px;
  color: red;
}


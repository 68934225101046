@import "../../../../../../styles/variables.scss";

.tabsContainer {
  margin-bottom: 24px;
  display: flex;
  justify-content: left;

  .button {
    width: 150px;
    border-radius: 0;
    font-size: 12px;
    font-weight: 500;
    background: transparent !important;
    color: $gray-1 !important;
    border-bottom: 1px solid $gray-1;

    &.button:hover {
      background: $gray-10 !important;
      color: $white-1 !important;
      box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
        0 17px 50px 0 rgba(0, 0, 0, 0.19);
    }
  }
  .selectedMenu {
    background: $gray-10 !important;
  }
}

@media screen and (min-width: $breakpoint-sm) {
  .tabsContainer {
    display: flex;
    justify-content: left;

    .button {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .tabsContainer {
    display: flex;
    justify-content: left;

    .button {
      width: 220px;
      font-size: 16px;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    .tabsContainer {
      .button {
        width: 310px;
        font-size: 18px;
      }
    }
  }
}

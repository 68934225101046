@import "../../styles/variables.scss";

.CalculationDemoBatchPage {
  margin-top: 120px;
  color: white;

  .title {
    font-size: 2.4rem;
    font-weight: 800;
    margin-bottom: 15px;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 1.6rem;
    }

    @media screen and (min-width: $breakpoint-xlg) {
      font-size: 2.5rem;
    }
  }

  .title2 {
    font-size: 1.25rem;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 15px;

    @media screen and (max-width: $breakpoint-md) {
      font-size: 1.5rem;
    }
    @media screen and (min-width: $breakpoint-xlg) {
      font-size: 2.5rem;
    }
  }

  .textContainer {
    width: 90%;
    margin: 0 auto;
    text-align: justify;
    text-justify: inter-word;
    hyphens: auto;
  }

  .textContainer > * {
    margin-bottom: 15px;
  }

  .textContainer > *:last-child {
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 10px;
  }

  .loadData {
    width: 97%;
    margin: 0 auto;
  }

  .fileManager {
    background-color: $blue-3;
    .elementBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 0px auto;
      padding: 10px 0;
      text-align: center;
      width: 100%;
      .element {
        margin: 5px auto;
      }
    }
  }

  .results {
    margin-top: 35px;
  }

  .firstButton {
    color: white;
    background-color: $blue-6 !important;
    width: 250px;
    // margin: 15px auto 5px 0;
    font-size: 13px;
  }

  .firstButton:hover {
    background-color: $blue-4 !important;
    color: $blue-6;
    box-shadow: 5px 5px 10px rgba(134, 176, 204, 0.5);
  }

  .lastButton {
    color: white;
    background-color: $blue-6 !important;
    width: 250px;
    // margin: 5px auto 15px 0;
    font-size: 13px;
  }

  .lastButton:hover {
    background-color: $blue-4 !important;
    color: $blue-6;
    box-shadow: 5px 5px 10px rgba(134, 176, 204, 0.5);
  }

  .emulateFile {
    color: white;
    background-color: $blue-6 !important;
    width: 250px;
    font-size: 13px;
    font-weight: 600;
    padding: 5px 0;
    border-radius: 30px;
    border: none;
  }

  .group{
    margin: 0 auto;
    display: flex;
    justify-content:center;
    gap: 5px;
    .txtInside{
      margin-top: 3px;
    }
  }

  .emulateFile:hover {
    background-color: $blue-4 !important;
    color: $blue-6;
    box-shadow: 5px 5px 10px rgba(134, 176, 204, 0.5);
  }
}

@import "../../styles/variables.scss";

.description {
  display: flex;
  justify-content: space-between;
  color: $gray-1;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
}

.sliderContainer {
  padding: 0 5px;

  :global(.MuiSlider-valueLabel) {
    color: $gray-1;
    margin-top: 59px;
    background-color: transparent;
  }

  :global(.MuiSlider-rail) {
    border: 10px;
    color: $blue-3;
    opacity: 1;
  }

  :global(.MuiSlider-thumb) {
    border: 4px;
    background: $red-1;
  }

  :global(.MuiSlider-markLabel) {
    color: $gray-1;
  }
}

.sliderValue {
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  padding-top: 5px;
    input {
      color: $white-1;
    }
}

.sliderUnit {
  font-size: 15px;
  margin-left: 15px;
  margin-top: 4px;
  color: $white-1;
}

@media screen and (min-width: $breakpoint-md) {
  .description {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .sliderContainer {
    padding: 0 5px;
  }

  .sliderValue {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    input {
      color: $white-1;
    }
  }
}

@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.container {
  width: 100%;
  padding-top: 150px;
  @include width-viewport;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;

  .element {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      color: $blue-4;
      width: 100%;
      font-size: 2rem;
      text-align: left;
      font-weight: bold;
      margin-bottom: 15px;

      @media screen and (max-width: $breakpoint-sm) {
        font-size: 1.5rem;
      }
    }

    .content {
      background: $gray-1;
      border-radius: 10px;
      padding: 1rem 2rem 1rem 4rem;
      width: 80%;
      text-align: left;
      font-size: 20px;
      letter-spacing: 0px;

      @media screen and (max-width: $breakpoint-sm) {
        width: 90%;
        padding: 0.5rem 1rem 0.5rem 2rem;
        font-size: 18px;
      }

      @media screen and (max-width: $breakpoint-xs) {
        width: 80%;
        font-size: 16px;
      }

      .text {
        margin-top: 2rem;
        color: $blue-3;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        width: 90%;

        .label {
          font-weight: bold;
        }
      }
    }
  }
}

@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.wrapper {
  @include light-shadow;

  &.single {
    box-shadow: none;
  }

  .navbarContainer {
    display: flex;
    background: $blue-6;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    @include width-viewport;

    .image {
      width: 180px;
    }

    .shortImage {
      width: 40px;
    }

    .space {
      width: 100%;
      max-width: 100px;
    }

    .actionsContainer {
      display: flex;
      width: 100%;
      max-width: 800px;
      justify-content: space-between;
      align-items: center;

      &.planPage {
        max-width: 600px;

        @media screen and (max-width: $breakpoint-xlg) {
          max-width: 500px;
        }
      }

      &.user {
        justify-content: flex-end;
      }

      .button {
        color: $gray-1;
        font-weight: bold;

        &:hover {
          text-decoration: underline $blue-5 3px;
          color: $blue-5;
          text-underline-offset: 5px;
          cursor: pointer;
        }
      }
      .activeButton {
        text-decoration: underline $blue-5 3px;
        color: $blue-5;
        text-underline-offset: 5px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .wrapper {
    .navbarContainer {
      padding: 5px;

      .space {
        display: none;
      }

      .shortImage {
        width: 30px;
      }

      .actionsContainer {
        justify-content: flex-end;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xl) {
  .wrapper {
    .navbarContainer {
      padding: 10px 30px;
    }
  }
}

.menuItemIcon {
  color: #fff;
}

.menuItemDivider {
  background: #fff;
}

.linkToPage{
  color: white;
  font-size: 16px;
  font-weight: bold;
  
  &:hover {
    text-decoration: underline white 3px;
    color: white;
    text-underline-offset: 5px;
    cursor: pointer;
  }
}

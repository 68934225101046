@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  margin-top: 150px;
  @include width-viewport;
  gap: 30px;
  color: $gray-1;

  .element {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      width: 100%;
      color: $gray-1;
      font-size: 36px;
      text-align: left;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .content {
      margin-top: 32px;
      color: $blue-3;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      width: 90%;

      .message {
        border-radius: 10px;
        padding: 24px;
        width: 100%;
        background-color: $gray-1;
        text-align: left;
        font-size: 20px;
        letter-spacing: 0px;
      }

      .planContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 30px;

        .planSection {
          display: flex;
          flex-direction: column;
          font-size: 24px;
          gap: 20px;

          .upperSection {
            display: flex;
            align-items: center;

            .star {
              margin-right: 10px;
              height: 30px;
            }

            .plan {
              color: $blue-4;
              margin-right: 20px;
              font-weight: 700;
              font-size: 24px;
            }
          }

          .planStatus {
            border: 3px solid $blue-4;
            color: $gray-1;
            padding: 10px 20px;
            border-radius: 25px;
            font-size: 20px;
            display: flex;
            align-items: center;

            .dot {
              display: inline-block;
              height: 10px;
              width: 10px;
              border-radius: 5px;
              margin-right: 10px;
            }

            .dotGreen {
              @extend .dot;
              background: $green-2;
            }

            .dotRed {
              @extend .dot;
              background: $red-1;
            }
          }
        }

        .paymentSection {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 24px;
          gap: 10px;
          font-size: 16px;
          color: $gray-1;

          .label {
            display: inline-block;
            color: $blue-4;
            font-size: 18px;
            padding-right: 15px;
          }

          .plan {
            color: $blue-4;
            margin-right: 20px;
            font-weight: 700;
            font-size: 24px;
          }
        }
      }
    }

    .profileContent {
      display: flex;
      width: 90%;
      justify-content: center;
      align-items: center;
      gap: 30px;
      flex-wrap: wrap;

      .photo {
        min-width: 30%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;

        .changeLanguage {
          display: flex;
          align-items: center;

          .label {
            margin-right: 10px;
          }

          // Input text color
          :global(.MuiFormLabel-root) {
            color: $gray-1;

            &:global(.Mui-disabled) {
              color: $gray-2;
            }
          }

          // Field text color
          :global(.MuiInputBase-root) {
            color: $blue-4;

            &:global(.Mui-disabled) {
              border-color: $gray-2 !important;
              color: $gray-2 !important;
              opacity: 1 !important;
            }
          }

          // Input borders
          :global(.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline) {
            border-color: $gray-1;
          }

          // Input borders
          &:global(.MuiInputBase-input .MuiOutlinedInput-input .Mui-disabled) {
            border-color: $gray-1;
          }
        }

        :global(.MuiSvgIcon-root ) {
          color: $gray-1;
        }

        .icon {
          font-size: 150px;
          color: $gray-1;
        }
      }

      .inputSection {
        display: flex;
        flex-direction: column;
        min-width: 400px;
        width: 50%;

        .input {
          margin-bottom: 15px;

          input:disabled {
            background: rgb(90, 90, 90);
          }
        }

        .saveChanges {
          width: 100%;

          .button {
            display: flex;

            justify-content: flex-end;
          }
        }

        @media screen and (max-width: $breakpoint-sm) {
          min-width: 300px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-xlg) {
    margin-top: 100px;
  }
}

@import "styles/variables.scss";

.modalParentForCreate {
  width: 60%;
  height: min-content;
  max-height: 70%;

  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .grid {
      display: grid;
      grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
      column-gap: 20px;
      row-gap: 20px;

      @media screen and (max-width: $breakpoint-sm) {
        grid-template-columns: minmax(200px, 1fr);
      }
    }
  }
}

.modalChildrenForCreate {
  overflow-y: auto!important;

  .modal {
    width: 90%;
    margin: auto;
  }
}

.modalParentForModelsSelection {
  width: 70%;
  height: min-content;
  max-height: 70%;

  .modelsTable {
    height: 100%;
  }
}

.modalParentForUsage {
  width: 70%;
  height: min-content;
  max-height: 70%;

  .modelsTable {
    height: 100%;
  }
}

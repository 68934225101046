@import "../../styles/variables.scss";

.container {
  font-size: 24px;
  color: $gray-1;
  font-weight: 700;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .position {
    margin-top: 28px;
    color: $blue-5;
  }

  .description {
    font-weight: 200;
    min-height: 100px;
  }

  .photo {
    max-height: 230px;
  }

  .linkedInButton {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    border: $blue-5 solid 3px;
    border-radius: 20.5px;
    color: $blue-5;
  }
}
